import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type VehicleType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  id: string;
  dcm: string;
  TruckType: string;
  Region: string;
  FuelType: string;
  BusinessUnit: string;
  AssetNumber: string;
  values: Array<VehicleData>;
};

export type VehicleData = {
  Date: string;
  VehicleIgnitionSignalVolts: number;
  StealthBatteryVolts: number;
  EngineCoolantTemperature: number;
  SystemTemperature: number;
  EngineRunning: boolean;
  InPark: boolean;
  J1939Valid: boolean;
  RPM: number;
  // Comment below out if no Map (Popup)
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;
