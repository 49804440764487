var dark = false;

if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  dark = true;
}
window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (e) => {
    dark = e.matches ? true : false;
  });

export default {
  darkmode: dark,
  gas: "5.11",
  footer: () => "Telematics powered by Stealth Intelligence",
  mapCenter: { lat: 30.190722, lng: -97.742207 },
};
